<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> {{ groupName }} members</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ <router-link to="/user-group">{{ userGroup.title }}</router-link> \ Members
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('group-member', 'create')" class="mt-4 btn btn-primary" style="color: #fff" @click="addMember">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add {{ groupName.toLowerCase() }} member
                                    </v-btn>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="9">
                                    <v-text-field class="form-control" v-model="search.user"
                                        label="Search by user name, email or CRN" outlined clearable
                                        v-on:keyup.enter="getMembers()" dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-btn :loading="loading" @click.prevent="searchMember()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>

                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>

                            <table class="table" v-if="!loading">
                              <thead>
                              <tr>
                                <th colspan="2" style="width: 450px !important; white-space: pre-wrap;">Student info</th>
                                <th style="width: 500px !important; white-space: pre-wrap;">Academic</th>
                                <th>Created date</th>
                                <th class="pr-3 text-center" style="width: 70px !important; white-space: pre-wrap;"></th>
                              </tr>
                              </thead>
                              <template v-if="members.length > 0">
                                <tr v-for="item in members" :key="item.id">
                                  <td class="text-center" style="width: 40px !important; white-space: pre-wrap;">
                                    <div class="symbol-label mt-2">
                                      <img v-if="item.image_path && item.image_path.real" :src="item.image_path.real"
                                           class="cursor-pointer" alt="" @click="changeImage(item)" style="height: 30px;">
                                      <span v-else class="symbol symbol-35 symbol-light-success" @click="changeImage(item)">
                                        <span class="symbol-label font-size-sm cursor-pointer">
                                          {{ item.first_name ? item.first_name.charAt(0).toUpperCase() : '' }}
                                          {{ item.last_name ? item.last_name.charAt(0).toUpperCase() : '' }}</span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                                      {{ item.user_full_name }}
                                    </a>|
                                    <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                                      <b> {{ item.user_crn }} </b> <i class="fa fa-book-open font-size-xs"></i>
                                      <span class="badge-dot" v-bind:class="{ 'bg-color-green': item.is_active, 'bg-color-red': !item.is_active }">
                                      </span><br />
                                                </a>
                                                <span class="text-secondary" v-if="item.user_setting && item.user_setting.midas_id">
                                      <b> External ID:</b> {{ item.user_setting.midas_id != null ? item.user_setting.midas_id : 'N/A' }}
                                    </span>
                                  </td>

                                  <td style="width: 500px !important; white-space: pre-wrap;">
                                    <span class="text-secondary">
                                      <b>{{ item.user_setting.program_title || "N/A" }}</b> |
                                      <b>{{ item.user_setting.current_semester_title || "N/A" }}</b> <br/>
                                      <b>{{ item.user_setting.current_class_title || "N/A" }}</b> |
                                      <b>{{ item.user_setting.academic_year || 'N/A' }}</b>
                                    </span>
                                  </td>
                                  <td>
                                    {{ item.created_at }}
                                  </td>
                                  <td class="text-center"  style="width: 70px !important; white-space: pre-wrap;">
                                    <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                      <template v-slot:button-content>
                                        <slot>
                                          <span>
                                              <i class="flaticon-more-1"></i>
                                          </span>
                                        </slot>
                                      </template>
                                      <!--begin::Navigation-->
                                      <div class="navi navi-hover ">
                                        <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('group-member', 'delete')">
                                          <a href="#" class="navi-link" @click="deleteMember(item.id)">
                                                              <span class="navi-icon">
                                                                  <i class="flaticon-delete"></i>
                                                              </span>
                                            <span class="navi-text"> Remove</span>
                                          </a>
                                        </b-dropdown-text>
                                      </div>
                                    </b-dropdown>
                                  </td>
                                </tr>
                              </template>
                              <tr v-if="members.length == 0">
                                <td class="text-center  px-3" colspan="10">No user added</td>
                              </tr>
                            </table>

                            <b-pagination
                                v-show="members.length > 0"
                                @input="getMembers"
                                class="pull-right mt-7"
                                v-model="page"
                                :total-rows="total"
                                :per-page="perPage"
                                first-number
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-member ref="add-member" @refresh="getMembers"></add-member>
    </v-app>
</template>

<script>
import AddMember from "./AddMember";
import UserGroupService from "@/core/services/user/user-group/UserGroupService";
import UserGroupMemberService from "@/core/services/user/user-group/user-group-member/UserGroupMemberService";

const userGroup = new UserGroupService();
const userGroupMember = new UserGroupMemberService();

export default {
    components:{
        AddMember
    },
    data() {
        return {
            search: {
                user: '',
            },
            loading: false,
            userGroup: {},
            page: null,
            perPage: null,
            total: null,
            members: []
        }
    },
    methods: {
      openSummaryPage(item) {
        this.$router.push({
          name: "students-summary",
          params: { id: item.id, type: 'student' }
        });
      },

      searchMember(){
          this.page = 1;
          this.getMembers();
      },

      showUserGroup() {
            this.loading = true;
            userGroup
                .show(this.userGroupId)
                .then(response => {
                    this.userGroup = response.data.userGroup;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
    },

      getMembers(){
          this.loading = true;
          userGroupMember
              .paginate(this.search, this.page, this.userGroupId)
              .then(response => {
                  this.members = response.data.data;
                  this.page = response.data.meta.current_page;
                  this.total = response.data.meta.total;
                  this.perPage = response.data.meta.per_page;
                  this.loading = false;
              })
              .catch(error => {
                  this.loading = false;
              });
      },

      addMember(){
          this.$refs['add-member'].showModal(this.userGroupId);
      },

      deleteMember(id){
          this.$confirm({
              message: `Are you sure you want to remove member?`,
              button: {
                  no: "No",
                  yes: "Yes"
              },
              /**
               * Callback Function
               * @param {Boolean} confirm
               */
              callback: confirm => {
                  if (confirm) {
                      userGroupMember
                      .delete(id)
                      .then(response => {
                          this.$snotify.success('Successfully removed from group')
                          this.getMembers();
                      })
                      .catch(error => {
                      });
                  }
              }
          });
      }
    },
    computed:{
        userGroupId(){
            return this.$route.params.userGroupId;
        }
    },
    mounted() {
        this.showUserGroup();
        this.getMembers();
    }
}
</script>
