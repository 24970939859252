import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class UserGroupMemberService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/user-group`;
    }

    paginate(data={},index = null, userGroupId) {
        let url = `${this.#api}/${userGroupId}/member`;
        if (index != null)
                url = `${url}?page=${index}`;
        let param ={
                params: data
        }
        return apiService.query(url,param);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);
    }

    store(data) {
        let url = `${this.#api}-member`
        return apiService.post(url, data);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}-member/${id}`
        return apiService.delete(url);
    }

    all() {
        let url = `${this.#api}/get/all`
        return apiService.get(url);
    }

    checkIfMemberExists(data={}, userId){
        let url = `${this.#api}-member/${userId}/check-user-exist`;

        let param ={
                params: data
        }

        return apiService.query(url,param);
    }
}
