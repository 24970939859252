<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Assign {{ groupName.toLowerCase() }} member
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                @change="selectUser"
                                v-model="selectedUser"
                                outlined
                                dense
                                :items="users"
                                hide-selected
                                item-text="display_text"
                                item-value="id"
                                :search-input.sync="userSearch"
                                @input="userGroupMember.user_id = $event !== null ? $event : ''"
                                :loading="isUserLoading"
                                clearable
                            >
                            <template v-slot:label>
                                <span class="text-danger"></span> Search by name, email or CRN
                            </template>
                            <template v-slot:no-data>
                                <div class="no-data-auto-complete text-center">No data available </div>
                            </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.userGroupMember.user_id.$error">This information is required</span>
                            <span class="text-danger" v-if="userExists">This user is already added in the group.</span>
                        </v-col>


                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                    :disabled="userExists"
                                    v-if="checkIsAccessible('group-member', 'create') || checkIsAccessible('group-member', 'edit')"
                                >
                                    Assign
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import UserGroupMemberService from "@/core/services/user/user-group/user-group-member/UserGroupMemberService";


const user = new UserService();
const userGroupMember = new UserGroupMemberService();

export default {
    validations: {
        userGroupMember: {
            user_id: {required},
            group_id: {required},
        }
    },
    data() {
        return {
            users: [],
            dialog: false,
            isBusy: false,
            userGroupMember: {
                user_id: null,
                group_id: null
            },
            isUserLoading: false,
            userSearch: '',
            userExists: false
        };
    },
    methods: {
        selectedUser(val)
        {
            if(!val){
                this.userGroupMember.user_id ='';
            }
        },
        selectUser()
        {
            if(this.selectedUser && this.selectedUser != null){
                this.userGroupMember.user_id = this.selectedUser;
                this.checkIfMemberExists();
            }else{
                this.userGroupMember.user_id = "";
            }
        },
        showModal(id = null) {
            if (id) {
                this.userGroupMember.group_id = id;
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },

        createOrUpdate() {
            this.$v.userGroupMember.$touch();
            if (this.$v.userGroupMember.$error) {
                setTimeout(() => {
                    this.$v.userGroupMember.$reset();
                }, 3000);
            } else {
                this.createGroupMember();
            }
        },
        createGroupMember() {
            this.isBusy = true;

            userGroupMember
            .store(this.userGroupMember)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.userGroupMember.$reset();
            this.userGroupMember = {
                user_id: null,
                group_id: null
            };
            this.userSearch = null;

            this.users = [];
            this.dialog = false;
            this.isBusy = false;
            this.isUserLoading = false;
            this.userExists = false;

        },
        checkIfMemberExists(){
            userGroupMember
            .checkIfMemberExists(this.userGroupMember, this.userGroupMember.user_id)
            .then((res) => {
                if(res.data.user_exists){
                    this.userExists = true;
                }else{
                    this.userExists = false;
                }
            })
            .catch((err) => {

            });
        }
    },
    watch:{
        userSearch(val) {
            if(val) {
              this.isUserLoading = true;

              const types = ['student'];

              let data = {
                val: val,
                types: types
              };

              user
                  .getByTypes(data)
                  .then((response) => {
                    response.data.users.map((user) => {
                      let data = user;
                      data.display_text =
                          user.full_name +
                          " | " +
                          user.personal_id +
                          " | " +
                          user.email
                      this.users.push(data);
                    });
                  })
                  .catch((err) => {

                  })
                  .finally(() => (this.isUserLoading = false));
            }
      }
    }
}
</script>
